<template>
    <header class="header">
        <div class="navigation-trigger hidden-xl-up" data-ma-action="aside-open" data-ma-target=".sidebar">
            <div class="navigation-trigger__inner">
                <i class="navigation-trigger__line"></i>
                <i class="navigation-trigger__line"></i>
                <i class="navigation-trigger__line"></i>
            </div>
        </div>

        <div class="header__logo hidden-sm-down">
            <h1>
                <router-link to="/">
                    Scripture Union
                </router-link>
            </h1>
        </div>

        <!-- <form class="search">
            <div class="search__inner">
                <input type="text" class="search__text" placeholder="Search for people, files, documents...">
                <i class="zmdi zmdi-search search__helper" data-ma-action="search-close"></i>
            </div>
        </form> -->

        <ul class="top-nav">
            <li class="hidden-xl-up">
                <a href="#" data-ma-action="search-open">
                    <i class="zmdi zmdi-search"></i>
                </a>
            </li>
            <li @click="logout">
                <a href="#" >
                    <i class="zmdi zmdi-power"></i>
                </a>
            </li>
        </ul>
    </header>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        async logout(){
			await this.$fb.auth().signOut()
            this.$router.push("/login")
        }
    }
}
</script>